import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
// import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
// import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import '../styles/services.css'
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import BlockContent from '@sanity/block-content-to-react';

// import Footer from '../components/Footer/footer';
import Navbar from '../components/Navbar/Navbar'
export const query = graphql`
  query ServicesPageQuery {
site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
    title
    description
    keywords
}
allSanityServices {
        nodes {
          topDescription
          servicesList {
            heading
            _rawDescription
            items
          }
        }
      }
   
  }
`;

const ServicesPage = props => {
  const { data, errors } = props;
  const services = data.allSanityServices.nodes[0].servicesList;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
//   const projectNodes = (data || {}).blogs
//     ? mapEdgesToNodes(data.blogs)
//         .filter(filterOutDocsWithoutSlugs)
//         .filter(filterOutDocsPublishedInTheFuture)
//     : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }


  return (
    <Layout>
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
        <div className="top-banner">
        <Navbar/>
<StaticImage className="banner" src='./images/4.png' alt="services"/>

        </div>
     
       <div className="service-content">At The Brand Digest we believe in building a holistic brand experience for both you and your customers. We offer consulting, strategic and creative services for your brands. We work towards spreading knowledge and awareness about the best branding techniques and towards educating our clients with the best luxury practices. This starts with some soul searching, looking deep inside the heart of a brand and helping them connect with their core audiences to build a loyal community centered on purpose and culture</div>
       {/* <div className="sub-title"> OUR PROCESS </div>
       <div className="brand-service-content center">
Step 1: Consultation<br/>
Step 2: Strategy & Concept<br/>
Step 3: Design & Execution<br/>
Step 4: Evaluation & Wrap-Up<br/>
 </div> */}
       <div className="grey-line"></div>
       
       <div>
      {services.map((service, index) => (
        <div key={index} className="brand-service-container">
          <div className="brand-service-heading">{service.heading}</div>
          <div className="content-flex-container">
            <div className="brand-service left">
              <div className="brand-service-content">
                <BlockContent
                  blocks={service._rawDescription}
                  // Replace with your Sanity dataset
                /> 
                 </div>
                </div>
                <div className=" brand-service-spacer"></div>
                <div className="brand-service list">
                  <div className="brand-service-content right">
                {service.items && (
                  <ul>
                    {service.items.map((item, itemIndex) => (
                      <li key={itemIndex}>{item}</li>
                    ))}
                  </ul>
                )} </div> </div>
             
          </div>
        </div>
      ))}
    </div>
<div className="brand-consultation">
 <div className="brand-consultation-image">
<StaticImage className="image-container" src="./images/consultation-banner.jpg" />
 </div>
<div className="brand-consultation-content">
    <div className="banner-heading"> REQUEST YOUR COMPLIMENTARY BRAND CONSULTATION </div>
<div className="banner-call-to-action">
<a href="https://form.jotform.com/212576608819466" className="request-consulation"> 
            Request Consultation
           
            
       </a>
</div>
</div>
</div>
      
    </Layout>
  );
};

export default ServicesPage;
